import React, { useState } from 'react';
import view from './personalDataForm.module.scss';
import axios from 'axios';

/**
 * @description Form component for managing personal data.
 * @prop {string} site_id - Site ID to post to lead router.
 * @function handleChange - handles change of input fields.
 * @function handleSubmit - handles submit of form & sends post data to AWS gateway URL.
 * 
 * @returns PersonalDataForm component
 */

const PersonalDataForm = (props) => {
    const [response, setResponse] = useState('');
    const [formData, setFormData] = useState({
        comments: "Do Not Sell My Personal Information",
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        street: '',
        city: '',
        regioncode: '',
        postalcode: ''
    });

    const { site_id,lead_url, data } = props;
    const { formText } = data

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            siteId: site_id, 
            ...formData,
            pageSource:props?.pageSource
        }
        axios.post(lead_url, data)
        .then(res => {
            let status = res.data.statusCode
            setResponse(status)
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (<>
        <div className={view["PersonalDataForm__title"]} dangerouslySetInnerHTML={{ __html:  formText}} />
        <form className={view["PersonalDataForm__form"]} onSubmit={handleSubmit}>
            <label className={view["PersonalDataForm__typeContainer"]}>
                <p>Select Request Type <span className={view["PersonalDataForm__required"]}>*</span>&nbsp;</p>
                <select required name="comments" value={formData.comments} onChange={handleChange}>
                    <option value="Do Not Sell My Personal Information">Do Not Sell My Personal Information</option>
                    <option value="Delete My Personal Information">Delete My Personal Information</option>
                    <option value="Access My Personal Information">Access My Personal Information</option>
                </select>
            </label>

            <div className={view["PersonalDataForm__inputContainer"]}>
                <label className={view["PersonalDataForm__input"]}>
                    <p>First Name <span className={view["PersonalDataForm__required"]}>*</span></p> 
                    <input required name="firstName" type='text' value={formData.firstName} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>Last Name <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="lastName" type='text' value={formData.lastName} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>Email <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="email" type='email' value={formData.email} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>Phone Number <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="phoneNumber" type='text' value={formData.phoneNumber} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>Address <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="street" type='text' value={formData.street} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>City <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="city" type='text' value={formData.city} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>State <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="regioncode" type='text' value={formData.regioncode} onChange={handleChange}/>
                </label>

                <label className={view["PersonalDataForm__input"]}>
                    <p>Zip Code <span className={view["PersonalDataForm__required"]}>*</span></p>
                    <input required name="postalcode" type='text' value={formData.postalcode} onChange={handleChange}/>
                </label>
            </div>

            {response === 200 ? <p className={view["PersonalDataForm__success"]}>Success! The message has been sent!</p> : <input className={view["PersonalDataForm__submit"]} type='submit' value="Submit" />}
            {response !== 200 && response !== '' ? <p className={view["PersonalDataForm__error"]}>Something went wrong. Please try again later.</p> : ''}
        </form>
        </>
    )
};


export default PersonalDataForm;